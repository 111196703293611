.export-pdf-icon{
    font-family: var(--font-bold) !important;
    color: #aacc00 !important;
    border-color: #aacc00 !important;
    background-color: white !important;
    width: 40% !important;
    height: 6vh !important;
}
.export-pdf-icon:hover{
    background-color: #aacc00 !important;
    color: white !important;
    border-color: #aacc00 !important;
    box-shadow: 2px 2px 4px #c1c1c1 !important;
}

.pdf-icon{
    color: #386641
}

.export-csv-icon{
    font-family: var(--font-bold) !important;
    color: #aacc00 !important;
    border-color: #aacc00 !important;
    background-color: white !important;
    width: 40% !important;
    height: 6vh !important;
}
.export-csv-icon:hover{
    background-color: #aacc00 !important;
    color: white !important;
    border-color: #aacc00 !important;
    box-shadow: 2px 2px 4px #c1c1c1 !important;
}

.csv-icon{
    color: #386641
}
