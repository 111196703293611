.main {
  height: 100vh;
  width: 100%;
  background-image: url('../../Assets/Frame20.jpg');
  background-color: #5BC9E2;
  display: flex;
  align-items: center;
}
  
.form-wrapper {
  width: 600px;
  float: right;
  padding: 30px;
  background: rgba(255, 255, 255, 0.9);
  margin: 50px;
  border-style: solid;
  border-color: darkgray;
  border-radius: 25px;
}
  
input[type="text"],
select.form-group {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
  
input[type="text"]:focus,
select.form-group:focus {
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
}

input[type="password"],
select.form-group {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
  
input[type="password"]:focus,
select.form-group:focus {
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
}
  
input[type="date"],
select.form-group {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
  
input[type="date"]:focus,
select.form-group:focus {
  background: transparent;
  border-bottom: 1px solid #000000;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.signup-btn {
  width: 100%;
  padding: 3%;
  margin-top: 5%;

  border: none;
  background: var(--secondary-color);
  border-radius: 9px;
  color: #ffffff;
  letter-spacing: 0.1em;
  font-weight: bold;
} 

.signup-btn:hover {
  background-color: #9cdeed;
}