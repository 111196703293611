.search-input-text{
    background: #d9f3f6;
    border: 1px solid #a6ddf9;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 2%;
    padding: 5px;
    padding-left: 5%;
    z-index: 0;
}

.date-input-text{
  background: #d9f3f6;
  border: 1px solid #a6ddf9;
  border-radius: 5px;
  box-sizing: border-box;
  width: 40%;
  margin-top: 2%;
  margin-right: 2%;
  padding: 5px;
  padding-left: 5%;
  z-index: 0;
}

.page-title{
    font-family: 'Gotham-Rounded-Bold';
    color: #3fd3ea;
    letter-spacing: 0.05em;
    font-size: 3.0rem;
  }

.branch-icon {
    display: inline;
    width: 2%;
    margin-left: -15%;
    color: #e70689;
    cursor: pointer;
  }

.search-icon{
    width: 25px;
    height: 25px;
    left: 10vw;
}

.history-cont{
    background: #d9f3f6;
    border: 1px solid #a6ddf9;
    border-radius: 5px;
    width: 100% !important;
    height: 9vw;
    background-color: #adebf5;
    padding-top: 2%;
}

.dashboard-icons{
    width: 90px;
    height: 90px;
    margin-left: 30px;
}

@media screen and (min-width: 450px) {
.subtitle {
    font-family: 'Gotham-Rounded-Bold';
    color: #255398;
    letter-spacing: 0.05em;
    font-size: 3rem;
    text-transform: uppercase;
  }

.text-label {
    font-family: 'Gotham-Rounded-Bold';
    color: #3e6db5;
    letter-spacing: 0.05em;
    font-size: 1rem;
    text-transform: uppercase;
}
}

@media screen and (max-width: 600px) {
    .subtitle {
        font-family: 'Gotham-Rounded-Bold';
        color: #255398;
        letter-spacing: 0.05em;
        font-size: 1.0rem;
        text-transform: uppercase;
        margin-left: -30%;
      }
    
    .text-label {
        font-family: 'Gotham-Rounded-Bold';
        color: #3e6db5;
        letter-spacing: 0.05em;
        font-size: 0.60rem;
        text-transform: uppercase;
        margin-left: -30%;
    }
    .page-title{
      font-family: 'Gotham-Rounded-Bold';
      color: #3fd3ea;
      letter-spacing: 0.05em;
      font-size: 1.8rem;
    }
    .search-input-text{
      background: #d9f3f6;
      border: 1px solid #a6ddf9;
      border-radius: 5px;
      box-sizing: border-box;
      width: 70%;
      margin-top: 2%;
      padding: 5px;
      padding-left: 5%;
      z-index: 0;
  }
  .dashboard-icons{
    width: 40px;
    height: 40px;
    margin-left: 5px;
    }
    .search-icon{
      width: 20px;
      height: 20px;
      left: 5vw;
  }
  .branch-icon {
    display: inline;
    width: 2%;
    margin-left: -10%;
    color: #e70689;
    cursor: pointer;
  }
  }
  .fXwTek {
    justify-content: left !important; 
    padding: 0 !important;
}
.llovFU {
  justify-content: right !important;
  padding: 0;
}
.glyQNg {
   padding-right: 1px !important;
}