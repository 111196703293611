/* VARIABLES */
:root {
  --primary-color: #61dafb;
  --secondary-color: #5BC9E2;
  --tertiary-color: #3E3E40;
  --navbar-color:#F3FDFF;


  --font-regular: 'Gotham-Rounded-Light';
  --font-bold: 'Gotham-Rounded-Bold';
  --font-medium: 'Gotham-Rounded-Medium';

  --font-sublabel: 'Concert-One'
}

/* TAGS */
hr{
  color: var(--secondary-color) !important;
}

/* CLASSES */
.App {
  font-family: var(--font-regular);
  color:var(--tertiary-color)
}

.header-label{
  font-family: var(--font-bold);
  font-size: xx-large;
  text-align: left;
  text-transform: capitalize;
  color:var(--tertiary-color);
  /* padding-left: 2% !important; */
  padding-bottom: 0%;
}

.badge{
  color:red !important
}

/* PADDINGS AND MARGINS */
.p-2{
  padding: 2% !important;
}

/* ALIGNMENTS */
.align-w-center{
  text-align: -webkit-center;
}
