.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.hr-line {
  color: #0c76df;
}

.forms-text {
  font-size: 15px;
}

.form-dropdown {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #4b4b4b;
  border-radius: 5px;
  padding: 3px;
  width: 50%;
  margin-bottom: 10px;
}

.form-input-text {
  border: 1px solid #4b4b4b;
  border-radius: 5px;
  width: 50%;
  height: 75%;
  padding: 0.5rem;
}

.form-input-upload {
  border: 1px solid #4b4b4b;
  border-radius: 5px;
  width: 100%;
  height: 70%;
  padding: 0.5rem;
  margin-top: 20px;
}
.btn-info {
  margin-right: 5px;
  color: rgb(248, 244, 244);
  font-weight: 800;
  background-color: #5bc9e2;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  margin-top: 50px;
}
.btn-add {
  color:#5bc9e2;
  font-weight: 800;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  margin-top: 50px;
}


.date-search-area {
  caret-color: #5bc9e2;
  color: rgb(40, 243, 236);
  border-radius: 5px;
}

.checkbox {
  background-color: #5bc9e2;
}

.action-btn {
  width: 100% !important;
}

.label-form{
  font-size: large;
  font-family: "Gotham-Rounded-Bold";
}


.placeholder_form{
  font-size: medium;
  font-family: "Gotham-Rounded-Medium";
  color: darkgrey;
}

@media screen and (max-width: 600px) {
  .add-btn,
  .save-btn,
  .submit-btn,
  .yes-btn {
    color: rgb(248, 244, 244);
    font-weight: bold;
    background-color: #5bc9e2;
    border: none;
    border-radius: 5px;
    padding: 2px 2px 2px 2px;
    margin-top: 20px;
    margin-left: 10px;
    width: 50% !important;
  }
}


