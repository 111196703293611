.action-btn.cancel{
    background:white;
    border-color: #ff002b;
    color:#ff002b;
    font-family:var(--font-bold);
}
.action-btn.cancel:hover{
    color:whitesmoke;
    border-color:#ff002b;
    background-color: #ff002b;
    font-family:var(--font-bold);
}

.action-btn.create{
    background:#48bfe3;
    border-color: #48bfe3;
    font-family:var(--font-bold);
}
.action-btn.create:hover{
    background:#43afd0;
    border-color: #43afd0;
    font-family:var(--font-bold);
}

.action-btn.update{
    background:#43afd0;
    border-color: #43afd0;
    font-family:var(--font-bold);
}
.action-btn.update:hover{
    background:#1a82a2;
    border-color: #43afd0;
    font-family:var(--font-bold);
}

.action-btn.clear{
    background:white;
    border-color: #9d4edd;
    color: #9d4edd;
    font-family:var(--font-bold);
}

.action-btn.clear:hover{
    color:whitesmoke;
    border-color: #9d4edd;
    background-color: #9d4edd;
}

.action-btn.loader{
    text-align: -webkit-center;
}
.action-btn.delete{
    background:#ff002b;
    border-color: #ff002b;
    font-family:var(--font-bold);
}
.action-btn.delete:hover{
    background:#c60223;
    border-color: #e70027;
    font-family:var(--font-bold);
}

.action-btn{
    box-shadow: 2px 2px 4px #c1c1c1;
}