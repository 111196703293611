.page-title {
    font-family: 'Gotham-Rounded-Bold';
    color: #3fd3ea;
    letter-spacing: 0.05em;
    font-size: 3.0rem;
  }
  
  .history-cont {
    background: #d9f3f6;
    border: 1px solid #a6ddf9;
    border-radius: 5px;
    width: 100% !important;
    height: 9vw;
    background-color: #adebf5;
    padding-top: 2%;
  }
  
  .dashboard-icons {
    width: 30px !important;
    height: 30px !important;
  }
 
  
  @media screen and (min-width: 450px) {
    .subtitle {
        font-family: 'Gotham-Rounded-Bold';
        color: #255398;
        letter-spacing: 0.05em;
        font-size: 3rem;
        text-transform: uppercase;
      }
    .content-text {
        font-family: 'Gotham-Rounded-Bold';
        color: #255398;
        letter-spacing: 0.05em;
        font-size: 27px;
        text-transform: uppercase;
      }
  
    .text-label {
        font-family: 'Gotham-Rounded-Bold';
        color: #3e6db5;
        letter-spacing: 0.05em;
        font-size: 1rem;
        text-transform: uppercase;
    }
  }
  
  @media screen and (max-width: 600px) {
    .subtitle {
      font-family: 'Gotham-Rounded-Bold';
      color: #255398;
      letter-spacing: 0.05em;
      font-size: 1.0rem;
      text-transform: uppercase;
    }
    
    .text-label {
      font-family: 'Gotham-Rounded-Bold';
      color: #3e6db5;
      letter-spacing: 0.05em;
      font-size: 0.60rem;
      text-transform: uppercase;
    }
  
    .page-title {
      font-family: 'Gotham-Rounded-Bold';
      color: #3fd3ea;
      letter-spacing: 0.05em;
      font-size: 1.8rem;
    }
  
    .dashboard-icons {
      width: 40px;
      height: 40px;
    }
  }
  
  .chart-size{
    width: 100% !important;
    height: 500px !important;
  }
  
  @media only screen and (width: 600px) {
  .chart-size{
    width: 100% !important;
    height: 100px !important;
  }
  }

  .qrcode {
    height: fit-content !important;
    width: fit-content !important;
    margin-top: -0.6rem;
  }

