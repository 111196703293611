.align-left{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.form-label{
    /* font-weight: bold; */
    font-family: var(--font-medium);
}

.form-check-input:checked[type=radio]{
    background: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
    box-shadow: 2px 2px 2px #c1c1c1;
}

.form-check-input:checked[type=checkbox]{
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
    box-shadow: 2px 2px 2px #c1c1c1;
}

.css-qbdosj-Input {
    grid-area: 1/1/2/1000 !important;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
    background-color: var(--secondary-color);

    opacity: 1;
    display: block;
    background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
} */