
  .login-content {
    display: flex !important;
    align-items: center;
    height: 100vh;
  }
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #555 !important;
    border-radius: 4px;
  }
  .LoginLogo {
    width: 60vw;
    height: 100vh;
    position: relative;
    /* background-color: #5BC9E2; */
  }
  .LoginLogo img {
    height: 100vh;
    width: 50vw;
    position: relative;
  }
  .container-row{
    --bs-gutter-x:0px !important;
   }
   
  .log {  
    /* background-color: #5BC9E2 !important; */
    display: flex;
    position: relative;
  }

  .log h2{
    margin-top: 30px;
    margin-bottom: 30px !important;
    text-align: left;
    font-size: 40px;
    font-weight: bold;
    color: #5BC9E2;
  }

  .log h1{
    text-align: center;
    position: relative;
    font-size: 1rem;
    font-style: normal;
    font-weight: bolder;
  
  }
  .log h3{
    text-align: right;
    position: relative;
    font-size: 10px;
  }
  .log h3 img {
    height: 5rem;
    width: 100%;
  }
  .log h4{
    padding: 0.5rem;
    text-align: center;
    font-size: 0.8rem;
  }
  
  .login {
    position: relative;
    width: 40vw;
    height: 100vh;
    font-style: normal;
    line-height: 48px;
    color: #fafafa;
  }
  .form-wrapp{
    background-color: #225ad8;
    height: 100vh;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-wrapp form {
    display: flex;
    flex-direction: column;
  }
  .form-control::placeholder {
    color: black;
  }
  label {
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
  }
  .confirm-pass{
    margin-top: 30px;
  }
  input[type="text"],
  select.form-group {
    font-style: normal;
    font-weight: 500;
    height: 2.5rem;
    background: #fafafa !important;
    border: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 1rem !important;
  }
  input[type="text-password"],
  select.form-group {
    font-style: normal;
    font-weight: 500;
    height: 2.5rem;
    background: #fafafa !important;
    border: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 1rem 0 0 1rem !important;
    
  }
  input[type="text-password"],
  select.form-group:active {
    box-shadow: none !important;
  }
  input[type="password"],
    select.form-group {
    font-style: normal;
    font-weight: 500;
    height: 2.5rem;
    background: #fafafa !important;
    border: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 1rem 0 0 1rem !important;
  }
  .input-group-append {
    height: 2.5rem;
    background: #fafafa;
    border-radius: 0 1rem 1rem 0;
    margin: 0 !important;
  }
  .show-btn {
    margin-top: -10px;
    border: none !important;
  }
  .show-btn:hover {
    color: black;
  }
  .show-btn:active svg {
    color: black;
  }
  .btn-info:hover{
    background-color: #5BC9E2 !important;
  }
  .btn-info {
    margin-top: 5% !important;
  }
  .btn-info, .btn-info:visited {
    width: 300px;
    height: 40px;
    position: center;
    font-style: normal;
    background-color: transparent !important;
  }
  .checkbox-wrapper input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-style: solid;
  }
  .links a {
    color: #5BC9E2;
    font-size: 0.9rem;
  }
  .links a:hover {
    color: #a0d3cd;
  }

  @media (max-height: 525px) {
    .log h3 img {
      display: none;
    }
  }

  @media (max-width: 800px) {
    .login-content {
      flex-direction: column;
    }
    .login {
      width: 100%;
    }
    .LoginLogo {
      width: 100%;
    }
    .LoginLogo img {
      width: 100%;
    }
  }